import {
    SET_DATABASE_TABLE_VALUES,
    SET_TABLE_TO_CUSTOMIZE,
    SET_SELECTED_COLUMNS,
    SET_DATABASE_TABLE_FILTERS,
    SET_DATABASE_TABLE_APPLIED_FILTERS, SET_SHARED_TYPE,
} from "redux/types";

/**
 * Sets the values of a table of the database
 * @param {"clients" | "projects" | "jobs" | "jobRequests" | "shifts" | "newemployess" | "employers" | "employees" | "applicants" | "singles" | "totals"} table
 * @param {{
 *  page?: number;
 *  size?: number;
 *  sortKey?: string;
 *  tab?: string;
 *  columns?: string[];
 *  items?: string[];
 *  fullItems?: any[];
 *  reset?: boolean;
 *  allListSelected?: boolean;
 *  total?: number;
 *  listJobRequests?: boolean;
 *  listInternalPerEmployee?: boolean;
 *  listFreelancers?: boolean;
 *  listConnections?: boolean;
 *  listIncomingEmployees?: boolean;
 * }} values
 */
export function setDatabaseTableValues(
  table,
  {
    page,
    size,
    sortKey,
    rebuildSortKey,
    tab,
    columns,
    items,
    fullItems,
    reset,
    allListSelected,
    total,
    listJobRequests,
    listInternalPerEmployee,
    listFreelancers,
    listConnections,
    listIncomingEmployees,
  }
) {
  return {
    type: SET_DATABASE_TABLE_VALUES,
    data: {
      table,
      page,
      size,
      sortKey,
      rebuildSortKey,
      tab,
      columns,
      items,
      fullItems,
      reset,
      allListSelected,
      total,
      listJobRequests,
      listInternalPerEmployee,
      listFreelancers,
      listConnections,
      listIncomingEmployees,
    },
  };
}

export function setSharedType(
    sharedType
) {
    return {
        type: SET_SHARED_TYPE,
        data: {
            sharedType,
        },
    };
}


/**
 * Sets selected table to be edited in the customization modal
 * @param {"clients" | "projects" | "jobs" | "jobRequests" | "shifts" | "newemployess" | "employers" | "employees" | "applicants" | "singles" | "totals" | null} table
 */
export function setTableToCustomize(table) {
  return (dispatch, getState) => {
    const { database } = getState();
    let selectedColumns = [];

    if (!!table) {
      selectedColumns = database[table].columns;
    }

    dispatch({
      type: SET_TABLE_TO_CUSTOMIZE,
      data: { table, selectedColumns },
    });
  };
}

/**
 * Sets selected columns in the customization modal
 * @param {string[]} selectedColumns
 */
export function setSelectedColumns(selectedColumns) {
  return {
    type: SET_SELECTED_COLUMNS,
    data: selectedColumns,
  };
}

/**
 * Sets the values of the filters of the database tables
 * @param {"clients" | "projects" | "jobs" | "jobRequests" | "shifts" | "newemployess" | "employers" | "employees" | "applicants" | "singles" | "totals"} table
 * @param {{
 *  [key: string]: any;
 * }} filters
 * @param {boolean} applied
 */
export function setDatabaseTableFilters(table, filters, applied = false) {
  return {
    type: applied
      ? SET_DATABASE_TABLE_APPLIED_FILTERS
      : SET_DATABASE_TABLE_FILTERS,
    data: { filters, table },
  };
}

export default setDatabaseTableValues;
