import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import createLogger from "redux-logger";
import rootReducer from "redux/reducers";
import { reduxDebugger } from "../../config/";

/*
 * @param {Object} initial state to bootstrap our stores with for server-side rendering
 * @param {History Object} a history object. We use `createMemoryHistory` for server-side rendering,
 *              while using browserHistory for client-side
 *              rendering.
 */
export default function configureStore(initialState, history) {
  // Installs hooks that always keep react-router and redux store in sync
  const middleware = [thunk, routerMiddleware(history)];
  let store;


  if (reduxDebugger) {
    middleware.push(createLogger());
    store = createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(...middleware),
            typeof window === "object" &&
            typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== "undefined"
                ? window.__REDUX_DEVTOOLS_EXTENSION__({trace: true, traceLimit: 25})
                : (f) => f
        )
    );
  } else {
    store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), (f) => f)
    );
  }

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("redux/reducers", () => {
      const nextReducer = require("../reducers");

      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
