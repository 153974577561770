function isSortDescending(sortKey){
    return sortKey.startsWith('-')
}

export function getSortOrderAndKey(sortKey){
    // if(sortKey.length === 0) return ['', '']
    if(!isSortDescending(sortKey)){
        return ['ASC', sortKey]
    }
    sortKey = sortKey.slice(1)
    return ['DESC', sortKey]
}

export function addSortParamsToUrl(url, sortKey){
    if(typeof sortKey !== 'string' || sortKey === ''){
        return url
    }
    const [order, sortName] = getSortOrderAndKey(sortKey)
    url.searchParams.set("sort", sortName);
    url.searchParams.set("order", order);
    return url
}

export function getPageNumber(pageNumber){
    if(pageNumber === 0){
        return 0
    }
    return pageNumber -1
}
