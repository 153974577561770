import axios from "axios";
import { serverConfig } from "../../config";
import {addSortParamsToUrl, getPageNumber} from "utils/rebuild/sort";
import {createRebuildFilterString} from "utils/rebuild/api";
const apiUrl = serverConfig.rebuildApiUrl;

const rebuildTableService = {
    getTableEntityCount(
        token,
        {
            entityName,
            isArchived = false,
            filters = {}
        }
    ) {
        const filtersString = createRebuildFilterString(filters?.rebuild);
        const url = new URL(`${apiUrl}${entityName}`);
        url.searchParams.append("isArchived", isArchived?.toString());
        url.searchParams.append("dataFetchMode", 'count');
        return axios.get(`${url.toString()}${filtersString}`,
            {
                headers: { Authorization: `JWT ${token}` }
            }
        );
    },
    getTableEntities(
        token,
        {
            entityName,
            sortKey = "name",
            isArchived = false,
            pagination = { page: 1, limit: 25 },
            filters = {}
        }
    ) {
        const filtersString = createRebuildFilterString(filters?.rebuild);

        const url = new URL(`${apiUrl}${entityName}`);
        addSortParamsToUrl(url, sortKey)
        url.searchParams.set("isArchived", isArchived?.toString());

        if (pagination) {
            url.searchParams.set("page", getPageNumber(pagination.page));
            url.searchParams.set("limit", pagination.limit);
        }

        return axios.get(`${url.toString()}${filtersString}`,
            {
                headers: { Authorization: `JWT ${token}` }
            }
        );
    },
    getBulkWorkFlows(token, shiftIds, shiftType = 'internal'){
        const url = new URL(`${apiUrl}workflows/bulk`);
        for (let i = 0; i < shiftIds.length; i++) {
            url.searchParams.append("shiftIds", shiftIds[i])
        }

        url.searchParams.append('sharedType', shiftType)
        return axios.get(url.toString(),
            {
                headers: { Authorization: `JWT ${token}` }
            }
        );
    },
    getBulkComments(token, shiftIds){
        const url = new URL(`${apiUrl}comments/bulk`);

        for (let i = 0; i < shiftIds.length; i++) {
            url.searchParams.append("fkUUIDS", shiftIds[i])
        }

        return axios.get(url.toString(),
            {
                headers: { Authorization: `JWT ${token}` }
            }
        );
    }
};

export default rebuildTableService;
