import {
  USER_LOGIN,
  SET_DATABASE_TABLE_VALUES,
  SET_DATABASE_TABLE_FILTERS,
  SET_DATABASE_TABLE_APPLIED_FILTERS,
} from "redux/types";
import { combineReducers } from "redux";
import {rebuildSortKey} from "./rebuild";

const columns = (
  state = [
    "title",
    "client",
    "custom_number",
    "start_date",
    "end_date",
    "city",
    "project_manager",
    "status",
  ],
  action = {}
) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.projects !== "undefined"
        ? action.data.preferences.database.projects.columns
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "projects" && action.data.columns
        ? action.data.columns
        : state;

    default:
      return state;
  }
};

const selectedTab = (state = "running", action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.projects !== "undefined"
        ? action.data.preferences.database.projects.selectedTab
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "projects" && action.data.tab
        ? action.data.tab
        : state;

    default:
      return state;
  }
};

const page = (state = 1, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.projects !== "undefined"
        ? action.data.preferences.database.projects.page
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "projects" && action.data.page
        ? action.data.page
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return 1;

    default:
      return state;
  }
};

const size = (state = 25, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.projects !== "undefined" &&
        action.data.preferences.database.projects.size <= 100
        ? action.data.preferences.database.projects.size
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "projects" && action.data.size
        ? action.data.size
        : state;

    default:
      return state;
  }
};

const sortKey = (state = "title", action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "projects" && action.data.sortKey
        ? action.data.sortKey
        : state;

    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.projects !== "undefined"
        ? action.data.preferences.database.projects.sortKey === "applied_users_count" ? "title" : action.data.preferences.database.projects.sortKey
        : state;

    default:
      return state;
  }
};

const filters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_FILTERS:
      return action.data.table === "projects"
        ? { ...state, ...action.data.filters }
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "projects"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const appliedFilters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "projects"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const selectedItems = (state = [], action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "projects" && action.data.items
        ? action.data.items
        : state;

    default:
      return state;
  }
};

const reset = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "projects" &&
        typeof action.data.reset === "boolean"
        ? action.data.reset
        : state;

    default:
      return state;
  }
};

const allListSelected = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "projects" &&
        typeof action.data.allListSelected === "boolean"
        ? action.data.allListSelected
        : state;

    default:
      return state;
  }
};

const total = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "projects" &&
        typeof action.data.total === "number"
        ? action.data.total
        : state;

    default:
      return state;
  }
};

export const projects = combineReducers({
  columns,
  selectedTab,
  size,
  page,
  sortKey,
  filters,
  appliedFilters,
  selectedItems,
  reset,
  allListSelected,
  total,
  rebuildSortKey: rebuildSortKey('projects')
});

export default projects;
