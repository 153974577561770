import { combineReducers } from "redux";
import { USER_LOGIN, USER_LOGOUT, SET_PROFILE, SET_TENANT, SET_TENANT_CONNECTIONS } from "redux/types";

const authenticated = (state = false, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return true;

    case USER_LOGOUT:
      return false;

    default:
      return state;
  }
};

const data = (state = null, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data;

    case USER_LOGOUT:
      return null;

    case SET_PROFILE:
      return action.data;

    case SET_TENANT:
      return action.data;

    default:
      return state;
  }
};

const tenants = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_TENANT:
      const connections = action?.data.tenant?.connections || []
      return setTenants(connections);

    default:
      return state;
  }
}

function setTenants(connections){
  const tenantMap = {};
  try {
    for (let i = 0; i < connections.length; i++) {
      const tenantOne = connections[i]["tenant_1"]
      const tenantTwo = connections[i]["tenant_2"]
      // duplicate tenants can override each other
      tenantMap[tenantOne.id] = tenantOne
      tenantMap[tenantTwo.id] = tenantTwo
    }
  } catch (e){
    // dont want to break anything
  }
  return tenantMap
}

const tenantConncetions = (state = null, action = {}) => {
  switch (action.type) {
    case SET_TENANT_CONNECTIONS:
      return action.data;

    default:
      return state;
  }
}

const user = combineReducers({
  authenticated,
  data,
  tenantConncetions,
  tenants
});

export default user;
