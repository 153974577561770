import axios from "axios";
import moment from "moment";
import momentTz from "moment-timezone";
import { serverConfig } from "../config";
const apiUrl = serverConfig.apiUrl;

const userService = {
  login(data) {
    return axios
      .post(`${apiUrl}/api/users/login/`, { ...data, login_from: "dashboard" })
      .then((response) => {
        const user = response.data.results;
        if (user.status) {
          return response;
        } else {
          return this.setCurrentTimeZone(user.token, user.uuid).then(
            () => response
          );
        }
      });
  },
  activateRegistration(data){
    return axios.post(`${apiUrl}/api/users/employee/registration/`, data);
  },
  setTwoFactorAuthentication: (data) => {
    return axios.post(
      `${apiUrl}/api/users/two-step-authentication-method/`,
      data
    );
  },
  setCurrentTimeZone: (
    token,
    userUuid,
    { requestConfig } = { requestConfig: {} }
  ) => {
    return axios.put(
      `${apiUrl}/api/users/profile/${userUuid}/`,
      {
        current_time_zone: moment().format("Z"),
        current_timezone: momentTz.tz.guess(true),
      },
      {
        ...requestConfig,
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createUser: (token, tenantUuid, payload) => {
    return axios.post(`${apiUrl}/api/users/${tenantUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  createApplicant: (token, tenantUuid, payload) => {
    return axios.post(`${apiUrl}/api/users/${tenantUuid}/`, payload, {
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getProfile(token, uuid, options = { requestConfig: {} }) {
    return this.setCurrentTimeZone(token, uuid, options);
  },
  setProfile: (token, uuid, payload) => {
    return axios.put(`${apiUrl}/api/users/profile/${uuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  setProfileRoles: (token, uuid, roles) => {
    return axios.put(
      `${apiUrl}/api/users/roles/${uuid}/`,
      { roles },
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  refreshToken: (token, uuid) => {
    return axios.get(`${apiUrl}/api/users/token/${uuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  checkEmail: (email, tenantUuid) => {
    return axios.post(`${apiUrl}/api/users/check/email/${tenantUuid}/`, {
      email,
    });
  },
  checkEmailAvailable: (email) => {
    return axios.post(`${apiUrl}/api/users/check/email-available/`, {
      email,
    });
  },
  checkUsername: (token, username) => {
    return axios.post(
      `${apiUrl}/api/users/check/username/`,
      { username },
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  sendCodeToResetPassword: (username, language) => {
    return axios.post(`${apiUrl}/api/users/password-reset/username/`, {
      language,
      username,
    });
  },
  resetPassword: (code, password, rePassword) => {
    return axios.post(`${apiUrl}/api/users/password-reset/`, {
      password,
      confirm_password: rePassword,
      code,
    });
  },
  reset2faUser: (token, userUuid) => {
    return axios.post(
      `${apiUrl}/api/users/reset-two-step-authentication/${userUuid}/`,
      {},
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  maskInvitationAsOpen: (token, employeeUuid, invitationUuid) => {
    return axios.put(
      `${apiUrl}/api/notifications/mark/as/opened/${employeeUuid}/${invitationUuid}/`,
      null,
      {
        headers: { Authorization: `JWT ${token}` },
        "Content-Type": "application/json",
      }
    );
  },
  completeRegistry: (token, employeeUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/users/complete/basic/register/${employeeUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
        "Content-Type": "application/json",
      }
    );
  },
  generateConnectionLink: (token) => {
    const BASE_URL = `${apiUrl}/api/shared/pin/`;
    return axios
      .post(BASE_URL, [], {
        headers: { Authorization: `JWT ${token}` },
      })
      .then((response) => {
        return response.data.results;
      });
  },
  updateDeviceTrack: (token, deviceTrackID) => {
    return axios
      .put(
        `${apiUrl}/api/users/device-track/`,
        { device_track_id: deviceTrackID },
        {
          headers: { Authorization: `JWT ${token}` },
        }
      )
      .then((response) => response.data.results);
  },
  getNotificationsAndEmailsPreferences(token, employeeUuid) {
    return axios
      .get(`${apiUrl}/api/users/notification/preferences/${employeeUuid}/`, {
        headers: { Authorization: `JWT ${token}` },
      })
      .then((response) => {
        return response.data.results;
      });
  },
  setNotificationsAndEmailsPreferences(token, employeeUuid, preferences) {
    return axios.post(
      `${apiUrl}/api/users/notification/preferences/${employeeUuid}/`,
      preferences,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  setNotificationsAndEmailsPreferencesBulk(token, employeeUuid, preferences) {
    return axios.put(
      `${apiUrl}/api/users/notification/preferences/${employeeUuid}/bulk`,
      preferences,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
};

export default userService;
