import moment from "moment/moment";

/**
 * Converts new back-end jobs to their old python variants
 *
 * @param newJob
 * @returns {{end_date: string, cancelled_at: null, request: null, applied_users: (Number|*), city, project: {title, uuid: string}, shared_job: null, house_number: string, full_address: string, uuid, zip_code: *, extra_note: (string|*), job_categories: [{name}], is_full, jap_with_shared_employee: boolean, house_number_ext: string, tenant: {only_accept_employees_from_afas: boolean, name: string, uuid: string}, start_date: string, brands: *, invited_users: *, address, is_owner, auto_accept_employee: boolean, number_of_employees: (*|number), max_cancellation_date: *, proposal_users: (number|*|number), accepted_users: (Number|*)}}
 */
const reformatToOldJob = (newJob) => {
    return {
        uuid: newJob.uuid,
        project: {
            uuid: newJob.project.uuid,
            title: newJob.project.title
        },
        accepted_users: newJob.acceptedUsers,
        number_of_employees: newJob.numberOfEmployees,
        extra_note: newJob.extraNote,
        job_categories: [{
            name: newJob.jobCategory.name
        }],
        brands: newJob?.project?.client,
        invited_users: newJob.invitedUsers,
        applied_users: newJob.appliedUsers,
        proposal_users: newJob?.proposalUsers ?? 0, // Not implemented in new jobs so always 0
        address: newJob.address,
        zip_code: newJob.postCode,
        house_number: newJob.houseNumber,
        house_number_ext: newJob.houseNumberExt,
        city: newJob.city,
        full_address: newJob.fullAddress,
        start_date: moment(newJob.startDate).format('DD-MM-YYYY HH:mm'),
        end_date: moment(newJob.endDate).format('DD-MM-YYYY HH:mm'),
        request: null, // Not implemented in new jobs
        shared_job: null, // Not implemented in new jobs
        is_owner: newJob.isOwner,
        max_cancellation_date: newJob.maxCancellationDate,
        cancelled_at: null, //  Not implemented in new jobs
        is_full: newJob.isFull,
        auto_accept_employee: false, // Not implemented in new jobs
        // tenant: {
        //     uuid: "b73ef650-82fa-46ac-9750-a1fe1e6cbefd", // Not implemented in new jobs
        //     name: "Colombia", // Not implemented in new jobs
        //     only_accept_employees_from_afas: false // Not implemented in new jobs
        // },
        jap_with_shared_employee: false // Not implemented in new jobs, always false
    }
}

export default reformatToOldJob