import React, { useEffect, useState, useMemo } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { injectIntl } from "react-intl";
/* Custom Hooks */
import { useCurrentDate } from "hooks/planner";
import { useScheduler } from "hooks/scheduler";
import { useSchedulerPreferences } from "hooks/scheduler/settings";
/* Config */
import getFiltersStructure from "./filters.config";

export const SchedulerContext = React.createContext({
  list: {
    employees: [],
    totalCount: 0,
    loading: false,
    filtersValues: {},
    filters: {},
    loadNext: () => Promise.resolve(),
    setFiltersValues: (values, restart = false) => {},
    applyFilters: () => {},
  },
  sidebar: {
    selectedDate: null,
    setSelectedDate: (date) => {},
    selectedEmployee: null,
    setSelectedEmployee: (employee, date) => {},
    plannedList: [],
    registeredList: [],
    invitedList: [],
    onClose: () => {},
    onRejected: (application) => {},
    onScheduled: (application) => {},
  },
  currentDay: moment(),
  currentWeek: moment().week(),
});

function Provider({ children, intl, location }) {
  const { schedulerPreferences } = useSchedulerPreferences();
  const [selectedDate, setSelectedDate] = useState(null);
  const [resetFlag, setResetFlag] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [filtersValues, setFiltersValues] = useState({
    minContractHours: "",
    maxContractHours: "",
    minZipCode: "",
    maxZipCode: "",
    pools: "",
    poolCategories: "",
    category: "",
    fullName: "",
    freeFields: "",
    isAvailableOn: "",
    isAvailableOption: "",
  });
  const [plannedList, setPlannedList] = useState([]);
  const [registeredList, setRegisteredList] = useState([]);
  const [invitedList, setInvitedList] = useState([]);
  const { currentDay, currentWeek } = useCurrentDate();
  const {
    employees,
    totalCount,
    getEmployees,
    loading,
    loadNext,
    editList,
  } = useScheduler({
    sortBy: schedulerPreferences.sortBy,
  });

  const isAvailableOnValue = useMemo(() => applyFilterIsAvailableOn(), [
    currentWeek,
    filtersValues.isAvailableOn,
  ]);

  const filters = useMemo(() => {
    return getFiltersStructure(intl, {
      minContractHours: filtersValues.minContractHours,
      maxContractHours: filtersValues.maxContractHours,
      minZipCode: filtersValues.minZipCode,
      maxZipCode: filtersValues.maxZipCode,
      pools: filtersValues.pools,
      category: filtersValues.category,
      fullName: filtersValues.fullName,
      freeFields: filtersValues.freeFields,
      isAvailableOn: isAvailableOnValue,
      isAvailableOption: filtersValues.isAvailableOption,
    });
  }, [
    filtersValues.minContractHours,
    filtersValues.maxContractHours,
    filtersValues.minZipCode,
    filtersValues.maxZipCode,
    filtersValues.pools,
    filtersValues.poolCategories,
    filtersValues.category,
    filtersValues.fullName,
    filtersValues.freeFields,
    isAvailableOnValue,
    filtersValues.isAvailableOption,
  ]);

  useEffect(() => {
    init(filters);
  }, [currentWeek, resetFlag]);

  return (
    <SchedulerContext.Provider
      value={{
        currentDay,
        currentWeek,
        list: {
          employees,
          totalCount,
          loading: true,
          filtersValues,
          filters,
          setFiltersValues: (newValues, restart = false) => {
            setFiltersValues({ ...filtersValues, ...newValues });

            if (restart) {
              setResetFlag(!resetFlag);
            }
          },
          loadNext: handleLoadMore,
          applyFilters: () => init(filters),
        },
        sidebar: {
          selectedDate,
          setSelectedDate,
          selectedEmployee,
          plannedList,
          registeredList,
          invitedList,
          setSelectedEmployee: handleSetSelectedEmployee,
          onClose: handleCloseSidebar,
          onRejected: handleRejectedApplication,
          onScheduled: handleScheduledApplication,
        },
      }}
    >
      {children}
    </SchedulerContext.Provider>
  );

  function init(filters) {
    const selectedCategories =
      filtersValues.pools && filtersValues.poolCategories
        ? filtersValues.poolCategories
            .split(",")
            .some((obj) => obj === filtersValues.category)
          ? filtersValues.poolCategories
          : filtersValues.category
          ? filtersValues.poolCategories?.concat(",", filtersValues.category)
          : filtersValues.poolCategories
        : filtersValues.category;

    handleCloseSidebar();
    if(location.pathname === "/scheduler/"){
      getEmployees(
        currentDay.startOf("isoWeek").format("DD-MM-YYYY"),
        currentDay.endOf("isoWeek").format("DD-MM-YYYY"),
        {
          filters: {
            ...filters,
            pools: { ...filters.pools, value: null },
            category: { ...filters.category, value: selectedCategories },
          },
        }
      );
    }
   
  }

  function handleLoadMore() {
    loadNext(
      currentDay.startOf("isoWeek").format("DD-MM-YYYY"),
      currentDay.endOf("isoWeek").format("DD-MM-YYYY"),
      { filters }
    );

    return Promise.resolve();
  }

  function handleCloseSidebar() {
    ReactDOM.unstable_batchedUpdates(() => {
      setSelectedDate(null);
      setSelectedEmployee(null);
      setPlannedList([]);
      setRegisteredList([]);
      setInvitedList([]);
    });
  }

  function handleSetSelectedEmployee(employee, date) {
    const planned = employee.job_applications.filter(
      (application) =>
        application.active_status !== "job_applied" &&
        moment(application.start_date, "DD-MM-YYYY").isSame(date, "day")
    );
    const registered = employee.job_applications.filter(
      (application) =>
        application.active_status === "job_applied" &&
        !application.invited_at &&
        moment(application.start_date, "DD-MM-YYYY").isSame(date, "day")
    );
    const invited = employee.job_applications.filter(
      (application) =>
        application.active_status === "job_applied" &&
        !!application.invited_at &&
        moment(application.start_date, "DD-MM-YYYY").isSame(date, "day")
    );

    ReactDOM.unstable_batchedUpdates(() => {
      setSelectedEmployee(employee);
      setSelectedDate(date);
      setPlannedList(planned);
      setRegisteredList(registered);
      setInvitedList(invited);
    });
  }

  function handleRejectedApplication(application) {
    const { jap_uuid: uuid } = application;
    const newEmployeeData = {
      ...selectedEmployee,
    };

    newEmployeeData.job_applications = newEmployeeData.job_applications.filter(
      (application) => {
        console.log({
          filtered: application.uuid !== uuid,
          application,
          newEmployeeData,
          uuid,
        });
        return application.uuid !== uuid;
      }
    );

    editList(
      employees.map((employee) =>
        employee.uuid === newEmployeeData.uuid ? newEmployeeData : employee
      )
    );
    handleSetSelectedEmployee(newEmployeeData, selectedDate);
  }

  function handleScheduledApplication(application) {
    const newApplication = {
      ...application,
      uuid: application.jap_uuid,
      job: {
        ...application.job,
        job_category_name: application.job.job_category.name,
      },
    };

    editList(
      employees.map((employee) => {
        if (employee.uuid === newApplication.employee.uuid) {
          employee.job_applications = employee.job_applications.concat(
            newApplication
          );
        }

        if (selectedEmployee && selectedEmployee.uuid === employee.uuid) {
          handleSetSelectedEmployee(employee, selectedDate);
        }

        return employee;
      })
    );
  }

  function applyFilterIsAvailableOn() {
    if (
      Array.isArray(filtersValues.isAvailableOn) &&
      filtersValues.isAvailableOn.length > 0
    ) {
      const daysAvailableOn = filtersValues.isAvailableOn.map(
        (day) => moment(day, "DD-MM-YYYY").isoWeekday() - 1
      );

      const starDate = currentDay
        .clone()
        .startOf("isoWeek")
        .format("DD-MM-YYYY");

      return daysAvailableOn.map((day) =>
        moment(starDate, "DD-MM-YYYY")
          .add(day, "days")
          .format("DD-MM-YYYY")
      );
    }
    return "";
  }
}

export default injectIntl(Provider);
