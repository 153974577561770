export function createRebuildFilterString(queries){
    const filters = []

    for (const key in queries) {
        const query = queries[key];
        if(!query) continue
        const filter = createFilter(query.type, query.key, query.value)
        filters.push(filter)
    }

    return filters.length ? `&${filters.join("&")}` : "";
}

function createFilter(type, queryName, queryValue) {
    if(type === "date"){
        return createDateFilter(queryName, queryValue);
    }

    if (Array.isArray(queryValue)) {
        return createArrayFilter(queryName, queryValue);
    }

    if (type === "array") {
        return createArrayFilterOutOfArrayString(queryName, queryValue);
    }

    if (typeof queryValue === "string") {
        return `${queryName}=${encodeURIComponent(queryValue)}`;
    }
    return "";
}

function createDateFilter(queryName, queryValue) {
    const date = parseDate(queryValue)
    return `${queryName}=${date}`
}

function createArrayFilter(queryName, queryValue) {
    const filters = []
    for (let i = 0; i < queryValue.length; i++) {
        const filter = queryValue[i]
        filters.push(`${queryName}=${filter}`)
    }
    return filters.join("&")
}

function createArrayFilterOutOfArrayString(queryName, queryValue) {
    queryValue = queryValue.split(',')
    const filters = []
    for (let i = 0; i < queryValue.length; i++) {
        const filter = queryValue[i]
        filters.push(`${queryName}=${filter}`)
    }
    return filters.join("&")
}



const DATE_REGEX = /^\d{2}-\d{2}-\d{4}$/; // Matches "DD-MM-YYYY"
const DATETIME_REGEX = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/; // Matches "DD-MM-YYYY HH:mm"

function parseDateString(dateString) {
    const [dd, mm, yyyy] = dateString.split("-");
    if (!dd || !mm || !yyyy) {
        return dateString
    }
    return `${yyyy}-${mm}-${dd}`;
}

export function parseDate(date) {
    if (DATE_REGEX.test(date)) {
        return parseDateString(date);
    }

    if (DATETIME_REGEX.test(date)) {
        const [datePart, timePart] = date.split(" ");
        const dateTimeIso = `${parseDateString(datePart)}T${timePart}:00Z`;
        return dateTimeIso
    }

    return date
}